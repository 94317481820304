// THIS SITE WAS CREATED FOR VEET PK
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Carousel from 'common/Carousel';
import VideoList from 'components/VideoList';
import RelatedArticles from 'components/RelatedArticles';
import RelatedProducts from 'components/RelatedProducts';
import { CAROUSEL_DEFAULT_SETTINGS } from 'gatsby-theme-husky/src/utils/constants';
import { MenPageProps } from './models';
import './MenPage.scss';

const MenPage: FC<MenPageProps> = ({
  data: {
    allUmbracoMen: {
      nodes: [
        {
          langProps: { urls, lang },
          seoProps: {
            seoMetaTitle,
            seoMetaDescription,
            seoMetaKeywords,
            seoExternalHreflangs,
            ogImage,
          },
          relatedArticles,
          relatedArticlesTitle,
          mainCarousel,
          relatedProducts: { relatedProductsTitle },
          relatedVideosTitle,
          relatedVideosPlayText,
          relatedVideos,
        },
      ],
    },
    tipsAndTricks,
    relatedProductsList: { relatedProductsNodes },
  },
}) => {
  const NextArrow = <button type="button">&gt;</button>;

  const relatedVideosSettings = {
    numberOfVideos: 10,
    playVideoText: relatedVideosPlayText,
    sectionTitle: relatedVideosTitle,
  };

  const carouselSettings = {
    ...CAROUSEL_DEFAULT_SETTINGS,
    slidesToShow: 2.3,
    infinite: false,
    arrows: true,
    nextArrow: NextArrow,
  };

  return (
    <Layout langProps={{ urls, lang }} className="men-page">
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Carousel
        {...{
          carouselImages: mainCarousel,
          className: 'main-carousel',
        }}
      />
      <RelatedProducts
        {...{
          relatedProductsNodes,
          relatedProductsTitle,
        }}
      />
      <VideoList
        videoListSettings={relatedVideosSettings}
        umbracoVideosNodes={relatedVideos}
        withCarousel
        modalClassNameModifier="men"
        {...{ carouselSettings }}
      />
      <RelatedArticles
        {...{
          relatedArticles,
          tipsAndTricks,
          relatedArticlesTitle,
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query MenQuery($pageId: String, $lang: String, $relatedProductsValues: [String]) {
    allUmbracoMen(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        mainCarousel {
          carouselItemsDesktop {
            ...umbracoImageWithAlt
          }
          carouselItemsMobile {
            ...umbracoImageWithAlt
          }
        }
        relatedArticlesTitle
        relatedArticles {
          articleBannerImage {
            ...umbracoImage
          }
          articleBannerImageAltText
          url
          title
          teaser
        }
        relatedProducts {
          relatedProductsTitle
        }
        relatedVideosTitle
        relatedVideosPlayText
        relatedVideos {
          description
          title
          videoImageAlt
          youtubeVideo
          videoCoverImage {
            ...umbracoImage
          }
        }
      }
    }
    tipsAndTricks: allUmbracoTipsAndTricks(filter: { langProps: { lang: { eq: $lang } } }) {
      relatedArticlesButtonNodes: nodes {
        relatedArticlesButton {
          ariaLabel
          text
        }
      }
    }
    relatedProductsList: allSalsifyProducts(
      filter: { sku: { in: $relatedProductsValues }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedProductsNodes: nodes {
        localProductImage {
          childImageSharp {
            fluid(quality: 10) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        relatedProducts {
          relatedProductsLearnMore
          relatedProductsTitle
          buyNow {
            link {
              url
              target
              name
            }
            ariaLabel
            text
            withReverse
          }
        }
        productTitle
        productTitleLong
        productTitleWithSize
        productTitleShort
        url
      }
    }
  }
`;

export default MenPage;
